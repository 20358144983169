<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        <span class="search-box-title">标题:</span>
        <el-input class="width_200" clearable v-model="search.title" placeholder="请输入标题"></el-input>
      </div>
      <div class="search-box">
        <span class="search-box-title">分类:</span>
        <el-select v-model="search.type" clearable>
          <el-option :value="0" label="全部"></el-option>
          <el-option v-for="item of Types" :key="item" :value="item.id" :label="item.title"></el-option>
        </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
        <el-button type="primary" size="medium" @click="showDialog()">添加模板</el-button>
      </div>
      <div class="search-box" v-if="selectRows.length > 0">
            <el-button type="danger" @click="delMulti">批量删除</el-button>
      </div>
    </div>
    <el-table :data="tableData" @selectionChange="selectChange" :header-cell-style="{ background: '#F7F8FA' }">
      <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" width="200" label="标题" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-badge v-if="scope.row.is_hot" value="hot" class="hot_item" type="danger" >
            <span>{{scope.row.title}}</span>
          </el-badge>
          <span v-else><span>{{scope.row.title}}</span></span>
        </template>
      </el-table-column>
      <el-table-column prop="image" label="图片" align="center" :show-overflow-tooltip="true">
        <template v-slot="scope">
          <ximg :src="scope.row.image" :width="30" :height="30" :enlarge="true" alt=""></ximg>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="价格" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getTypesBy(scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column prop="times" label="大小" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{parseSize(scope.row.size)}}
        </template>
      </el-table-column>
      <el-table-column prop="times" label="次数" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="extend" label="脸部数据" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{parseDetect(scope.row.extend)}}
        </template>
      </el-table-column>
      <el-table-column prop="addtime" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="enable" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch :active-value="1" :inactive-value="0" v-model="scope.row.status" disabled></el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>

      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="showDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row.id)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />
    <!-- 添加/修改 -->
    <el-dialog :title="title" v-model="dialog" width="600px" top="10vh">
      <el-row justify="left" align="right">
        <el-col :span="4" align="right">标题:</el-col>
        <el-col :span="12">
          <el-input v-model="form.title" placeholder="标题" size="small"></el-input>
        </el-col>
        <el-col :span="2" align="right">价格:</el-col>
        <el-col :span="6"><el-input type="number" v-model="form.price" placeholder="价格" size="small"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">次数:</el-col>
        <el-col :span="12">
          <el-input v-model="form.times" placeholder="次数" size="small"></el-input>
        </el-col>
        <el-col :span="2" align="right">权重:</el-col>
        <el-col :span="6"><el-input type="number" v-model="form.weight" placeholder="权重" size="small"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">分类:</el-col>
        <el-col :span="20">
          <el-select v-model="form.types" multiple>
            <el-option v-for="item of Types" :key="item" :value="''+item.id" :label="item.title"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">状态:</el-col>
        <el-col :span="8">
          <el-switch :active-value="1" :inactive-value="0" v-model="form.status"></el-switch>
        </el-col>
        <el-col :span="4" align="right">热门:</el-col>
        <el-col :span="8">
          <el-switch :active-value="1" :inactive-value="0" v-model="form.is_hot"></el-switch>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">图片:</el-col>
        <el-col :span="20">
          <el-upload class="avatar-uploader" style="width: 300px;height: 300px;" accept="image/*" action="#" :show-file-list="false" :http-request="upload">
            <ximg  :enlarge="false"  v-if="form.image" :src="form.image" class="avatar" ></ximg>
            <el-button v-else type="primary">上传</el-button>
            <template #tip>
              <div class="el-upload__tip">
                jpg/png.
              </div>
            </template>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" align="center">
          <el-button type="primary" @click="dialog=false">取消</el-button>
          <el-button type="primary" @click="add" v-if="row === undefined">添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" align="center">
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "ai_image",
  components: {
    page,
  },
  data() {
    return {
      pager:{total: 0, page: 1, rows: 20},
      search:{
        title:"",
        type:0,
      },
      tableData:[],
      Types:[], // 分类
      title:"添加",
      dialog:false,
      form:{
        title:"",
        price:0,
        status:0,
        times:3000,
        size:0,
        is_hot:0,
        types:[], // 分类ID
        image:"",
        imgUrl:"",// 显示图片地址
      },
      row:undefined,
      selectRows:[],
      per_slice: 1024 * 1024,
    }
  },
  computed: {},
  created() {},
  mounted() {
    this.getTypes()
    this.getList()
  },
  methods: {
    parseSize(size) {
      size = size / 1024
      if (size < 1024){
        return size.toFixed("2") + "K"
      }
      size = size / 1024
      return size.toFixed("2") + "M"
    },
    parseDetect(extend){
      if (extend){
        var data = JSON.parse(extend)
        return data.detect
      }
      return ""
    },
    getTypesBy(type){
      var titles = []
      for(const id of type.split(",")){
        var title = this.getType(id)
        if (title != ""){
          titles.push(title)
        }
      }
      return titles.join(",")
    },
    getType(id){
      for(const t of this.Types){
        if (t.id == id){
          return t.title
        }
      }
      return ""
    },
    delMulti(){
      if (this.selectRows.length == 0){
        return this.$message({message:"请选择模板"})
      }
      var ids = []
      for(const row of this.selectRows){
        ids.push(row.id)
      }
      this.del(ids.join(","))
    },
    del(ids){
      this.$confirm("确认删除ID: " + ids + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        httpClient("ai_image_del").post({ids:ids}).then((res)=>{
          if (res.code == 0){
            this.getList()
            return this.$message({message:"删除成功",type:"success"})
          }else{
            return this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    selectChange(rows){
      this.selectRows = rows
    },
    edit(){
      if (this.form.types){
        this.form.type = this.form.types.join(",")
      }else{
        this.form.type = ""
      }
      this.$common.showLoading("保存中...")
      httpClient("ai_image_edit").post(this.form).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.dialog = false
          this.$message({message:"修改成功",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    add(){
      if (this.form.types){
        this.form.type = this.form.types.join(",")
      }else{
        this.form.type = ""
      }
      this.$common.showLoading("正在添加,并生成脸部数据")
      httpClient("ai_image_add").post(this.form).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.getList()
          this.dialog = false
          this.$message({message:"添加成功",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    upload(param) {
      var headers = {
        "is-thumbnail":"true",
        "is-encode":"true",
        "is-webp":"false",
        'Name':"af"
      }
      this.$common.showLoading("图片上传中")
      // this.$message({ message: "上传中请稍后", type: "success" });
      httpClient("").setHeader(headers).upload(param.file).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.form.image = res.data.filename
          this.form.thumbnail = res.data.thumbnail
          this.form.size = res.data.size
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
      })
    },
    showDialog(row){
      this.row = row
      this.form = {
        title:"",
        price:0,
        status:0,
        times:3000,
        size:0,
        is_hot:0,
        types:[], // 分类ID
        image:"",
        imgUrl:"",// 显示图片地址
      }
      if (row===undefined){
        this.title = "添加"
      }else{
        this.title = "编辑"
        this.form = row
        if (row.type != ""){
          this.form.types = row.type.split(",")
        }
      }
      console.log(this.form)
      this.dialog = true;
    },
    getList(){
      var params = {
        pageid:this.pager.page-1,
        pcount:this.pager.rows,
      }
      params = {...params,...this.search}
      httpClient("ai_image_list").post(params).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    getTypes(){
      httpClient("getTypeSelect").post({type:4}).then((res)=>{
        if (res.code == 0){
          this.Types = res.data
        }else{
          this.$message({message:"读取漫画分类失败:"+res.msg,type:"error"})
        }
      },(err)=>{
        console.log(err)
      })
    }
  }
};
</script>

<style>

/* 图片上传部分 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 300px;
  height: 300px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 300px;
  height: 300px;
  display: block;
}
.el-row{margin-bottom: 10px;}
.hot_item .el-badge__content{
  margin-top:10px; margin-right:-10px; color:#FFFFFF; background: red;
}
</style>